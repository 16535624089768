import {
  GET_FIXED_ASSETS_SUMMARY_DETAILS,
  POST_FIXED_ASSETS_SUMMARY_DETAILS,
  PUT_FIXED_ASSETS_SUMMARY_DETAILS,
} from "../constants/actionTypes";

const defaultState = {
  fixedAssetsSummaryDetails: [],
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_FIXED_ASSETS_SUMMARY_DETAILS:
        return { ...state, fixedAssetsSummaryDetails: [{...action.payload}] };
      case POST_FIXED_ASSETS_SUMMARY_DETAILS:
        return { ...state };
      case PUT_FIXED_ASSETS_SUMMARY_DETAILS:
        return { ...state };
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
