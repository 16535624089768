import { GET_DASHBOARD_STAGES } from "../constants/actionTypes";

const defaultState = {
  dashboardStages:[],
}
let index = "";

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case GET_DASHBOARD_STAGES:
        return { ...state, dashboardStages: [...action.payload] };
      // case POST_DEPRECIATION_SCHEDULE:
      //   return { ...state, ...action.payload };
      // case PUT_DEPRECIATION_SCHEDULE:
      //   return { ...state, ...action.payload };
      // case DELETE_DEPRECIATION_SCHEDULE:
      //   return [...state.filter((data) => data?.id != action?.payload)];
      default:
        return state;
    }
  } catch (error) {
    throw new Error(error);
  }
};
