import axios from "axios";
import { logoutUser } from "./redux/actions/profile";
import { store } from "./redux/store";
import { showTimeExpiredModal } from "./redux/actions/utils";

axios.interceptors.request.use(
  function (config) {
    const lastLoggedInTime = localStorage.getItem("lastLoggedInTime");
    const lastValidatedLoggedInTime = localStorage.getItem("lastValidatedLoggedInTime");
    if (lastLoggedInTime && lastValidatedLoggedInTime) {
      const timeElapsed = Date.now() - lastLoggedInTime;
      if (timeElapsed > 3600000) {
        localStorage.removeItem("lastValidatedLoggedInTime");
        store.dispatch(logoutUser()).then(() => window.location.href = "/login");
      } else if (timeElapsed > 1800000) {
        store.dispatch(showTimeExpiredModal());
      }else {
        const timeNow = Date.now();
        localStorage.setItem("lastLoggedInTime", timeNow)
        localStorage.setItem("lastValidatedLoggedInTime", timeNow)
      }
    }
    if (localStorage.getItem("token")) {
      config.headers["content-type"] = `application/json`
      config.headers["Authorization"] = `Token ${localStorage.getItem("token")}`;
    }
    return config;
  }
);

export default axios;
